<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.13046 0.880459L3.88956 4.3744H6.49147L10.828 1.77258L10.7627 1.51152C10.5832 0.793362 9.7464 0.469836 9.13046 0.880459Z"
    />
    <path
      d="M15.5979 4.3745L14.8415 2.1052C14.5654 1.27694 13.5829 0.930653 12.8485 1.40276L8.22573 4.3745H15.5979Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.5 7.44718C0.5 6.15394 1.54838 5.10556 2.84162 5.10556H15.777C17.0702 5.10556 18.1186 6.15394 18.1186 7.44718V10.9153C18.9072 11.0785 19.5 11.777 19.5 12.614C19.5 13.4509 18.9072 14.1494 18.1186 14.3126V16.9133C18.1186 18.2066 17.0702 19.2549 15.777 19.2549H2.84162C1.54838 19.2549 0.5 18.2066 0.5 16.9133V7.44718ZM17.7657 10.8793H15.1635C14.2055 10.8793 13.4289 11.656 13.4289 12.614C13.4289 13.572 14.2055 14.3486 15.1635 14.3486H17.7654L17.7657 15.7857H15.1637C13.412 15.7857 11.9919 14.3657 11.9919 12.6139C11.9919 10.8622 13.412 9.44211 15.1637 9.44211H17.7657V10.8793ZM5.79309 8.57484C6.34733 8.57484 6.79664 9.02414 6.79664 9.57839V11.1768H8.39458C8.94882 11.1768 9.39813 11.6261 9.39813 12.1803C9.39813 12.7346 8.94882 13.1839 8.39458 13.1839H6.79664V14.7822C6.79664 15.3365 6.34733 15.7858 5.79309 15.7858C5.23884 15.7858 4.78954 15.3365 4.78954 14.7822V13.1839H3.19074C2.6365 13.1839 2.18719 12.7346 2.18719 12.1803C2.18719 11.6261 2.6365 11.1768 3.19074 11.1768H4.78954V9.57839C4.78954 9.02414 5.23884 8.57484 5.79309 8.57484Z"
    />
  </svg>
</template>
